/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("sans");
    color: $font-color;
}


/*==============================
=            Titles            =
==============================*/


.t-t1 {}
.t-t2 {}
.t-t3 {}



/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    h1,
    h2,
    h3,
    h4 {
        margin: 1.414em 0 0.5em;
        line-height: 1.2;
        font-weight: 700;
    }

    a {

        &:hover {
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


small {
    font-size: 0.75em;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
}

a {
    color: inherit;
    text-decoration: none;
}
