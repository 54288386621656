
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

// // Socials
// .svg-instagram {
//     width: 1em;
//     height: 1em;
// }

